/**
 * regular
 */
@mixin font-light() {
    font-family: "Innogy light", sans-serif;
}

.font-light {
    @include font-light;
}
/**
 * regular
 */
@mixin font-regular() {
    font-family: "Innogy regular", sans-serif;
}

.font-regular {
    @include font-regular;
}


/**
 * BOLD
 */
@mixin font-medium() {
    font-family: "Innogy Bold", sans-serif;
}

.font-medium {
    @include font-medium;
}


/**
 * MEDIUM
 */
@mixin font-bold() {
    font-family: "Innogy Bold", sans-serif !important;
}

.font-bold {
    @include font-bold;
}

/*
 * TAG OVERRIDES
 */
strong {
    @include font-medium;
}

