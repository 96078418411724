/*
 * MENU LOGOS
 */
.provider-menu-logo {
    width: 140px;
    height: 140px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
}

.provider-menu-logo.envia {
    background-image: url("~src/assets/img/logos/providers/enviam.png");
}

.provider-menu-logo.eprimo {
    background-image: url("~src/assets/img/logos/providers/eprimo.png");
}

.provider-menu-logo.energis {
    background-image: url("~src/assets/img/logos/providers/energis.png");
}

.provider-menu-logo.lew {
    background-image: url("~src/assets/img/logos/providers/lew.png");
}

.provider-menu-logo.eon {
    background-image: url("~src/assets/img/logos/providers/eon.png");
}

.provider-menu-logo.avacon {
    background-image: url("~src/assets/img/logos/providers/avacon.png");
}

.provider-menu-logo.shn {
    background-image: url("~src/assets/img/logos/providers/shn.png");
}

.provider-menu-logo.bn {
    background-image: url("~src/assets/img/logos/providers/bw.png");
}

.provider-menu-logo.edis {
    background-image: url("~src/assets/img/logos/providers/edis.png");
}
