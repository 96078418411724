@import "../colors";

$icon-size-s: 20px;
$icon-size-m: 32px;
$icon-size-l: 45px;

@mixin icon($path:null, $size: null, $is-mask: null) {
    display: inline-block;

    @if $size == m {
        width: $icon-size-m !important;
        height: $icon-size-m !important;
    } @else if $size == l {
        width: $icon-size-l !important;
        height: $icon-size-l !important;
    } @else {
        width: $icon-size-s !important;
        height: $icon-size-s !important;
    }

    @if ($path) {

        @if ($is-mask == true) {
            mask-image: url($path);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;

            -webkit-mask-image: url($path);
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center;

        } @else {
            background-image: url($path);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

i.icon-plus {
    @include icon('/assets/img/icons/plus.png', m);
}

i.icon-minus {
    @include icon('/assets/img/icons/plus.png', m);
}

i.icon-info {
    @include icon('/assets/img/icons/info.png', m, true);
    background-color: #333;
}

i.step-complete {
    @include icon('~src/assets/img/icons/step-complete.webp', l, true);
    background-color: $iona-orange;
}

i.step-in-progress {
    @include icon('~src/assets/img/icons/step-complete.webp', l, true);
    background-color: black;
}

i.icon-dots {
    @include icon('~src/assets/img/icons/dots.png', l, true);
    background-color: black;
}

i.link {
    @include icon('~src/assets/img/icons/link.svg', m, true);
    background-color: black;
}

i.collapsed {
    @include icon('~src/assets/img/icons/collapse.png', l, true);
    background-color: black;
}

i.expand {
    @include icon('~src/assets/img/icons/expand.png', l, true);
    background-color: black;
}


