@import "~src/styles/colors";

a {
    color: $text-color;
    padding-top: 5px;
    padding-bottom: 5px;

    text-decoration: none;

    border-bottom: 1px solid $text-color;

    font-family: "Innogy regular", sans-serif;
    font-size: 18px;
}

a:hover {
    border-color: $iona-orange;
}

a.inversed {
    color: white;
    border-bottom: 1px solid $text-color;
}

// SIDE LINKS
a.side {
    font-size: 16px;
    border-bottom: none;

    &:hover {
        border-color: transparent;
    }
}

// MENU LINKS
a.menu-item {
    border-color: transparent;
    font-size: 22px;

    &:hover {
        border-color: transparent;
        color: $iona-orange;
    }
}

a.menu-link {
    border-color: transparent;
    font-size: 20px;

    &:hover {
        border-color: transparent;
        color: $iona-orange;
    }
}

// WHITE
a.white {
    color: white;
    background-color: transparent;
    border-color: white;

    &:hover {
        border-color: $iona-orange;
    }
}
