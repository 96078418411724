@import "fonts";

.text-input {
    height: 55px;
    width: 100%;
    padding: 0 20px;
    margin: 0;

    background: transparent;

    font-family: "Innogy light", sans-serif;
    font-size: 22px;
    font-weight: lighter;
    color: #333333;

    border: 2px solid #D9D9D9;
    -webkit-border-radius: 27px;
    -moz-border-radius: 27px;
    border-radius: 27px;
}

/**
 * EON DROPDOWN MIMIC
 */
$eon-dropdown-border-width: 1px;
.eon-dropdown {
    box-sizing: border-box;
    position: relative;
    border: $eon-dropdown-border-width solid #8f9192;
    border-radius: 3px;
    height: 42px;

    min-width: 80px;

    margin: 0;
    padding: 0;


    display: flex;
    flex-direction: row;

    > label {
        width: 0;
        height: 0;
        visibility: hidden;
    }

    > select {
        width: 100%;
        height: 100%;
        background-color: white;
        padding-left: 12px;
        padding-right: 42px;

        @include font-regular;
        font-size: 18px;

        cursor: pointer;
    }

    > span {
        position: absolute;
        right: calc(#{$eon-dropdown-border-width});
        top: calc(#{$eon-dropdown-border-width});

        background-color: #f6f6f7;
        pointer-events: none;

        background-image: url("/assets/img/icons/chevron-down/chevron-down.svg");
        //background-size: 24px 24px;
        background-size: 16px 10px;
        background-position: 50% 50%;
        background-repeat: no-repeat;


        height: calc(100% - #{$eon-dropdown-border-width * 2});
        width: 38px;
    }
}

.eon-dropdown:hover {
    transition: border-color 200ms ease;
    border-color: #39393a;

    > span {

    }
}
