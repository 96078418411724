/**
 * layout
 */

.fh {
    height: 100%;
}

.fw {
    width: 100%;
}

.inflate {
    width: 100% !important;
    height: 100% !important;
}

.center-contents {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-contents-v {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.right-align-contents {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-align-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.-row-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.col-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.frow {
    display: flex;
    flex-direction: row;
}

.splitrow {
    display: flex;
    flex-direction: row;

    > * {
        width: 50%;

        &:nth-child(odd) {
            margin-right: 7.5px;
        }

        &:nth-child(even) {
            margin-left: 7.5px;
        }
    }

}

.scroll-list {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
}


/**
 * GENERAL
 */

.grow-space {
    flex-grow: 1 !important;
}

.inline-el {
    display: inline-block;
}
